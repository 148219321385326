// Helper to convert straight number to percentage
@function convertify($number) {
  @if type-of($number) == 'number' and unitless($number) {
    @return percentage($number / $grid-column-count);
  }

  @return $number;
}

@mixin grid-row(
  $direction: ltr,
  $align: stretch,
  $justify: flex-start,
  $grid-gutter: $gutter,
  $wrap: wrap
) {
  display: flex;
  flex-wrap: $wrap;

  align-items: $align;
  justify-content: $justify;

  // margin-left: -($grid-gutter/2);
  // margin-right: -($grid-gutter/2);

  @if $direction == ltr {
    flex-direction: row;
  } @else {
    flex-direction: row-reverse;
  }
}

@mixin grid-col($width: 100%, $align: stretch, $grid-gutter: $gutter) {
  flex: none;
  align-self: $align;

  padding-left: $grid-gutter/2;
  padding-right: $grid-gutter/2;

  width: convertify($width);
}

@mixin grid-offset-left($offset: 1) {
  margin-left: convertify($offset);
}

@mixin grid-offset-right($offset: 1) {
  margin-right: convertify($offset);
}

.row {
  @include grid-row();
}

// Generates column classes like like col__1 -> col_12
.col {
  @for $i from 1 through $grid-column-count {
    &__#{$i} {
      @include grid-col($i/12 * 100%);

      &__t {
        @media only screen and (max-width: $min-tablet) {
          @include grid-col($i/12 * 100%);
        }
      }

      &__m {
        @media only screen and (max-width: $min-mobile) {
          @include grid-col($i/12 * 100%);
        }
      }
    }
  }
}

.margin {
  &__t {
    margin-top: $margin-big;

    &__t {
      @media only screen and (max-width: $min-tablet) {
        margin-top: $margin-big;
      }
    }

    &__m {
      @media only screen and (max-width: $min-mobile) {
        margin-top: $margin-big;
      }
    }
  }

  &__ts {
    margin-top: $margin-small;

    &__t {
      @media only screen and (max-width: $min-tablet) {
        margin-top: $margin-small;
      }
    }

    &__m {
      @media only screen and (max-width: $min-mobile) {
        margin-top: $margin-small;
      }
    }
  }

  &__b {
    margin-bottom: $margin-big;

    &__t {
      @media only screen and (max-width: $min-tablet) {
        margin-top: $margin-big;
      }
    }

    &__m {
      @media only screen and (max-width: $min-mobile) {
        margin-top: $margin-big;
      }
    }
  }

  &__bs {
    margin-bottom: $margin-small;

    &__t {
      @media only screen and (max-width: $min-tablet) {
        margin-top: $margin-small;
      }
    }

    &__m {
      @media only screen and (max-width: $min-mobile) {
        margin-top: $margin-small;
      }
    }
  }
}

.column_reverse {
  &__m {
    @media only screen and (max-width: $min-mobile) {
      flex-direction: column-reverse;
    }
  }
}
