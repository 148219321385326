@mixin light() {
  body {
    background-color: $color-background;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: black;
  }

  p,
  a,
  span,
  li {
    color: black;
  }
}
