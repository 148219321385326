// * {
//   background-color: red;
// }
@import '../../assets/styles/base.scss';

.button__import {
  // transition: 350ms ease all;
  // background-color: $color-primary;
  // color: white;
  // padding: $margin-small / 6 $margin-small / 2;
  // font-weight: bold;
  // border: 3px solid lighten($color-primary, 10);
  // width: 160px;
  // text-align: center;
  // &:hover {
  //   background-color: darken($color: $color-primary, $amount: 10);
  //   cursor: pointer;
  // }
}

.import__status {
  // padding: 10px 0;
  margin-top: 6px;
  span {
    line-height: 15px;
    font-size: 10px;
    margin-left: 6px;
  }
}

.import__status__progress {
  display: flex;
}
