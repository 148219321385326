@import '../assets/styles/base.scss';

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: $margin-big;
  border-radius: 10px;

  .login_logo {
    width: 80px;
  }

  form {
    padding: $margin-big;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div {
      width: 100%;
      margin-bottom: $margin-small;
    }
  }

  @media only screen and (max-width: $min-mobile) {
    width: 100%;
    height: 100%;
    form {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.label {
}

.labelFocused {
  color: black !important;
}

.inputRoot {
}

.inputRootFocused {
  fieldset {
    border-color: darkgrey !important;
  }
}

.button {
  padding: $margin-small / 2 $margin-small;
  font-size: 15px;
  margin-top: $margin-small / 2;
}
