@import '../../assets/styles/base';

.container {
  min-height: 330px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  flex-direction: column;
  // padding: $margin-small 0;
}

.holder {
  flex-grow: 1;
}

.tabs {
  width: 100%;
}

.tabs_container {
  width: 100%;

  margin-top: $margin-small;
}

.tab__button {
  font-size: 10px !important;
  padding: 0 !important;

  min-height: $margin-small !important;
}

.tabs__inner {
  min-height: $margin-small !important;
}
