@import '../../assets/styles/base';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
}

.holder {
  width: 100%;
}

.graph {
  margin: 0 auto;
  max-width: 550px;
  min-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
}

.periodLabel {
  font-weight: bold;
  margin-right: 10px;
}

.summary {
  overflow: auto;
  transition: 350ms ease all;
  height: 140px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $margin-small;
  background-color: #fbfbfb;
  border: 3px solid white;
  padding: $margin-small / 2;
  span {
    @include f-size-multiplier($base-multiplier * 0.55);
    display: block;
  }
}

header {
  h3,
  span {
    display: inline;
    @include f-size-multiplier($base-multiplier * 0.8);
  }
  span {
    @include f-size-multiplier($base-multiplier * 0.7);
  }
}

.config {
  // TODO: hacky
  margin-bottom: -40px;

  background-color: #f9f9f9;
  padding: $margin-small;
  display: block;

  &__top {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__buttons {
      margin-left: auto;
    }
  }
}
