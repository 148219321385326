@import '../../assets/styles/base';

.container {
  height: 100%;
  padding-bottom: $margin-small;

  .loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
