@import '../assets/styles/base.scss';

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  &__login_logo {
    width: 80px;
  }

  &__text {
    display: flex;
    width: 100%;
    // margin-top: $margin-big;
    margin-bottom: $margin-small * 1.5 !important;
    padding-right: $margin-big;
    // padding: 0 $margin-big * 2 0 $margin-big;

    p {
    }
  }

  &__error {
    p {
      color: red;
    }
  }

  &__success {
    p {
      color: green;
    }
  }

  &__form {
    padding: $margin-big;

    > div {
      width: 100%;
      margin-bottom: $margin-small;
    }
  }

  &__holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: $margin-big;
    border-radius: 10px;

    @media only screen and (max-width: $min-mobile) {
      width: 100%;
      height: 100%;
      form {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.label {
}

.labelFocused {
  color: black !important;
}

.inputRoot {
}

.inputRootFocused {
  fieldset {
    border-color: darkgrey !important;
  }
}

.button {
  padding: $margin-small / 2 $margin-small;
  font-size: 15px;
  margin-top: $margin-small / 2;
  width: auto;
  display: inline-block;
}
