@import './assets/styles/base';

.green {
  color: #11ac4c;
}

.red {
  color: #d35217;
}

.neutral {
  color: rgb(97, 97, 97);
}

.no_active_holding {
  background-color: #fbfbfb;
}

.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-cell {
  padding: 0 10px 0 5px !important;
  &:focus {
    outline: none !important;
  }
}

.MuiDataGrid-colCell {
  padding: 0 0 0 5px !important;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-footer {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #fbfbfb !important;
}

.MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: #fbfbfb !important;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: $min-mobile) {
    display: block;
  }
}

.content-area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.quick-view {
  background-color: white;
  height: 100px;
  padding: $margin-small;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $color-background;

  .description {
    margin-top: -2px;
    span {
      font-weight: bold;
      color: grey;
      @include f-size-multiplier($base-multiplier * 0.58);
    }
  }

  .number {
    span {
      font-weight: bold;
      @include f-size-multiplier($base-multiplier * 0.75);
    }
  }

  .summary {
    display: flex;
    > * {
      margin-top: 2px;
      margin-right: $margin-small * 1.5;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .import {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
  }

  @media only screen and (max-width: $min-mobile) {
    padding: $margin-small / 2;
    height: auto;
    .import {
      display: none;
    }

    .properties {
      display: none;
    }

    .summary {
      > * {
        margin-right: $margin-small;
      }
    }
  }
}

.view {
  height: 100%;
  // padding: $margin-small;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;

  > .row {
    width: 100%;
    max-width: 1600px;
  }

  @media only screen and (max-width: $min-mobile) {
    padding: 0;
    padding-top: $margin-small;
    overflow-x: hidden;
  }
}

.property_block {
  // padding: $margin-small / 2 $margin-small;
  background-color: white;
  display: flex;
  flex-direction: column;

  > div {
    padding: $margin-small $margin-small / 2;

    &.top {
      display: flex;
      // padding-top: $margin-small;
      // padding-bottom: $margin-small;
      flex-direction: column;
      flex-grow: 1;
    }
    &.bottom {
      flex-grow: 1;

      display: flex;
      padding-bottom: 0;
      padding-top: 0;
      min-height: 600px;
      flex-direction: column;
    }
  }

  &.divider {
    border-right: 2px solid $color-background;
  }

  .x {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;
    padding: $margin-small;
  }

  .chart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;
    height: 100%;
    // padding: $margin-small;
  }

  @media only screen and (max-width: $min-mobile) {
    padding: $margin-small / 2 $margin-small / 2;
  }
}

.table {
  height: 100%;
  padding: $margin-small/2 0;

  @media only screen and (max-width: $min-mobile) {
    height: 400px;
    padding: 0;
  }
}

.main-navigation {
  display: flex;
  flex-direction: column;
  width: 90px;
  background-color: #1f1f1f;
  height: 100%;
  header {
    background: none;
    height: 100px;
  }
  .logo {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      max-width: 40px;
    }
  }

  .nav-divider {
    height: 1px;
    width: 100%;
    background-color: #424242;
  }

  nav {
    display: flex;
    flex-direction: column;
    padding: 0 15px 15px 15px;
    height: 100%;
    .main {
      flex-grow: 1;
      height: 100%;
    }

    li {
      list-style: none;
      padding: 15px 0;
      text-align: center;
      a,
      span {
        font-size: 12px;
        color: #828282;
      }
    }
  }

  @media only screen and (max-width: $min-mobile) {
    width: 100%;
    height: 33px;
    flex-direction: row;

    header {
      height: 100%;
      height: 47px;
    }
    nav {
      padding: 0;
      margin-left: auto;

      .main {
        display: none;
      }
      .nav-divider {
        display: none;
      }

      .profile-image {
        display: none;
      }
      li {
        padding: 0;
      }
      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 $margin-small/2;
      }
    }

    .logo {
      padding: 0 $margin-small / 2;
      justify-content: flex-start;
      height: 100%;
      img {
        height: 30px;
        max-width: auto;
      }
    }
  }
}
