@import 'config';

@mixin f-size-multiplier($multiplier: 1) {
  @include responsive-font(
    $font-size-min * $multiplier,
    $font-size * $multiplier
  );
}

:root {
  @if global-variable-exists(scale) {
    --scale-element: 1;
    --scale-font: 1;

    @each $size in $scale {
      @media (max-height: nth($size, 1)) {
        --scale-element: #{nth($size, 2)};
        --scale-font: #{nth($size, 2)};
      }
    }
  }
}

body {
  @include responsive-font($font-size-min, $font-size);
  font-family: $font-family;
  line-height: $line-height;
  text-size-adjust: 100%; // iOS on orientation change
  color: $color-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  font-weight: bold;
}

$base-multiplier: 1.618;

h1 {
  @include f-size-multiplier($base-multiplier * 1.5);
  line-height: $base-multiplier / 1.2;
}

h2 {
  @include f-size-multiplier($base-multiplier * 1.2);
}

h3 {
  @include f-size-multiplier($base-multiplier * 0.8);
}

h4 {
  @include f-size-multiplier($base-multiplier * 0.6);
}

h5 {
  @include f-size-multiplier($base-multiplier * 0.5);
}

p,
span,
a,
li {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
  line-height: $line-height;
  // letter-spacing: 0.9px;
}

li {
  line-height: $line-height * 1.2;
}

a {
  text-decoration: underline;

  &:-webkit-any-link {
    text-decoration: underline;
  }
}

span {
  &.subtext {
    font-weight: bold;
    font-family: $font-heading;
  }
  &.fat_subtitle {
    font-weight: bold;
    font-family: $font-heading;
    color: #343434;
    @include f-size-multiplier($base-multiplier * 1);
    line-height: 25px;
  }
  &.bold {
    font-weight: bold;
  }
  &.divider {
    font-weight: bold;
    &:before {
      content: ' | ';
    }
  }
}

article {
  p {
    line-height: 1.9;
  }
}
