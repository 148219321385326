@import './reset';
@import './fonts';
@import './themes/light';

// Load default theme
html {
  @include light();
}

/* stylelint-disable selector-max-type  */
@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto; // always show the vertical scrollbar so that content doesn't jump
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

// inherited from <html>
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

// placeholders
input,
textarea,
select {
  &::-webkit-input-placeholder {
    color: $color-placeholder;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $color-placeholder;
  }

  &:-ms-input-placeholder {
    color: $color-placeholder;
  }
}

article {
  header {
    margin-bottom: $margin-small;
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.button {
  transition: 350ms ease all;
  background-color: $color-primary;
  color: white;
  padding: $margin-small / 6 $margin-small / 2;
  font-weight: bold;
  border: 3px solid lighten($color-primary, 10);
  width: 160px;
  text-align: center;
  @include f-size-multiplier($base-multiplier * 0.55);

  &:hover {
    background-color: darken($color: $color-primary, $amount: 10);
    cursor: pointer;
  }

  &:-webkit-any-link {
    text-decoration: none;
  }
}

.c {
  &__blue {
    color: #66d8ee !important;
  }
  &__yellow {
    color: #e6dc6b !important;
  }
}
